/**
 * Typography
 * @path: utilities/typography
 *
 * 1. Alignment
 * 2. Sizes
 * 3. Font weights
 * 4. Font decoration
 *
 */

// 1. Alignment
// ------------------------
@each $breakpoint-size in $breakpoint-classes {
    @include breakpoint($breakpoint-size) {
        @each $align in (left, right, center, justify) {
            @if $breakpoint-size != $zero-breakpoint {
                .u-#{$breakpoint-size}-text-#{$align} {
                    text-align: $align;
                }
            }
            @else {
                .u-text-#{$align} {
                    text-align: $align;
                }
            }
        }
    }
}

// 2. Sizes
// ------------------------

.u-font-small {
    font-size: $body-font-size * .75;
}

.u-font-base {
    font-size: $body-font-size;
}

.u-font-medium {
    font-size: $body-font-size * 1.25;
}

.u-font-large {
    font-size: $body-font-size * 1.5;
}

// 3. Font weights
// ------------------------
.u-font-weight-regular {
    font-weight: $global-weight-regular;
}

.u-font-weight-bold {
    font-weight: $global-weight-bold;
}

// 4. Font decoration
// ------------------------
.u-font-line-through {
    text-decoration: line-through
}

.u-break-word {
    word-break: break-word;
}
