/**
 * Vue datepicker overrides
 * @path: vendor/vue-datepicker
 */

@import 'variables';
@import '@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss';

.dp__main {
    max-width: 46rem;
    width: 100%;
}

.dp__instance_calendar {
    width: 100%;
    padding: 2rem;
}

.dp__calendar_header {
    width: 100%;
}

.dp__calendar_item {
    display: flex;
    justify-content: center;
}

.dp__calendar {
    width: 100%;
}

.dp__active_date {
    background-color: $primary;
}

.dp__today {
    border-color: $black;
}

.dp__date_hover,
.dp__active_date,
.dp__today {
    border-radius: 50% !important;
}