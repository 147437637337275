/**
 * Accordion
 * @path: components/accordion
 */

.c-accordion {
    &__item {
        border-bottom: $accordion-border;
        &--active {
            .c-accordion__content {
                display: block;
            }
            .c-accordion__header {
                padding-bottom: 0;
            }
        }
    }
    &__header {
        padding: $accordion-padding 0;
        width: 100%;
        text-align: left;
        cursor: pointer;
    }
    &__content {
        display: none;
    }
    &__icon {
        padding-left: $accordion-padding;
    }
    &__icon,
    &__title {
        margin: 0;
        color: $body-font-color;
        pointer-events: none;
    }

    &--panel {
        .c-accordion__content {
            border: $accordion-border;
            border-radius: 0 0 .5rem .5rem;
            border-top: none;
        }
        .c-accordion__item {
            margin-bottom: 1.2rem;
            &:nth-last-child(1) {
                margin-bottom: 0;
            }
        }
        .c-accordion__item--active {
            .c-accordion__header {
                border-bottom: 0;
                border-radius: .5rem .5rem 0 0;
            }
        }
        .c-accordion__header {
            border: $accordion-border;
            padding: $accordion-padding;
            border-radius: .5rem;
        }
    }
}
