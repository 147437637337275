/**
 * Dropdown
 * @path: components/dropdown
 */

.c-dropdown {
    .vs__dropdown-toggle {
        border: none;
        width: auto;
    }
    .vs__selected-options {
        width: 3rem;
        display: unset;
        flex-basis: unset;
        flex-grow: unset;
    }
    .vs__dropdown-menu {
        border-top-style: solid;
    }
    .vs__actions {
        margin-left: .4rem;
        padding-right: 0;
    }
    .vs__open-indicator {
        fill: $black;
    }
}
