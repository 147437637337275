/**
 * Headings
 * @path: elements/headings
 */

h1, h2, h3, h4, h5, h6 {
    font-family: $header-font-family;
    font-weight: $header-font-weight;
    line-height: $header-line-height;
}
