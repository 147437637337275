/**
 * Widget
 * @path: components/widget
 */

.c-widget {
    display: flex;
    justify-content: center;
}

@include breakpoint(lg) {
    .c-widget {
        justify-content: flex-end;
    }
}
