/**
 * Functions for retrieving specific values
 * @path: tools/value
 *
 * 1. Map keys
 * 2. Get specific side of value
 * 
 */


// 1. Map keys
// ------------------------
/**
 * Find the next key in a map.
 *
 * @author Rianne Oosthoek <rianne@click.nl>
 * @param {Map} $map - Map to traverse.
 * @param {Mixed} $key - Key to use as a starting point.
 * @returns {Mixed} The value for the key after `$key`, if `$key` was found. If `$key` 
 * was not found, or `$key` was the last value in the map, returns `null`.
 */
 @function -cl-get-next-map-key($map, $key) {
    // Store the keys of the map as a list
    $values: map-keys($map);
    $i: 0;

    // If the key exists, get the index of the key within the map and add 1 to it 
    // for the next breakpoint in the map
    @if (map-has-key($map, $key)) {
        $i: index($values, $key) + 1;
    }
    // If the key doesn't exist, or it's the last key in the map, return null
    @if ($i > length($map) or $i == 0) {
        @return null;
    }
    // Otherwise, return the value
    @else {
        @return map-get($map, nth($values, $i));
    }
}

// 2. Get specific side of value
// ------------------------
/**
 * Determine a top/right/bottom/right value on a padding, margin, etc. property, 
 * no matter how many values were passed in. Use this function if you need to 
 * know the specific side of a value, but don't know if the value is using a shorthand format.
 *
 * @author Rianne Oosthoek <rianne@click.nl>
 * @param {List|Number} $value - Value to analyze. Should be a shorthand sizing property, e.g. "1em 2em 1em"
 * @param {Keyword} $side - Side to return. Should be `top`, `right`, `bottom`, or `left`.
 * @returns {Number} A single value based on `$value` and `$side`.
 */
@function -cl-get-side($value, $side) {
    $length: length($value);

    @if $length == 1 {
        @return $value;
    }
    @if $length == 2 {
        @return map-get((
            top: nth($value, 1),
            bottom: nth($value, 1),
            left: nth($value, 2),
            right: nth($value, 2),
            ), $side);
    }
    @if $length == 3 {
        @return map-get((
            top: nth($value, 1),
            left: nth($value, 2),
            right: nth($value, 2),
            bottom: nth($value, 3),
            ), $side);
    }
    @if $length == 4 {
        @return map-get((
            top: nth($value, 1),
            right: nth($value, 2),
            bottom: nth($value, 3),
            left: nth($value, 4),
            ), $side);
    }
}
