/**
 * Link
 * @path: components/link
 */

.c-link {
    transition: $nav-item-transition;

    &:hover {
        transition: $nav-item-transition;
        color: $primary;
        text-decoration: underline;
    }

    @include external-url('fal', 'arrow-up-right-from-square', $body-font-size * 0.75);
}