/**
 * Checkbox
 * @path: components/checkbox
 */

 .c-checkbox-wrapper {
    position: relative;

    .c-checkbox {
        display: none;
    }

    .c-checkbox + label::after {
        content: none;
    }

    .c-checkbox:checked + label::after {
        content: '';
    }

    .c-checkbox-label {
        margin-left: 0;
        padding-left: 3.5rem;
        color: $black;
        position: relative;
        font-size: 1.5rem;

        &::before{
            opacity: .8;
            content: '';
            display: inline-block;
            position: absolute;
            top: .2rem;
            left: 0;
            background-color: $white;
            height: 2rem;
            width: 2rem;
            border: .1rem solid $dark-gray;
            border-radius: 4px;
        }

        &::after {
            position: absolute;
            content: '';
            display: inline-block;
            height: .8rem;
            width: 1.2rem;
            border-left: .2rem solid;
            border-bottom: .2rem solid;
            transform: rotate(-45deg);
            left: .4rem;
            top: .6rem;
            color: $primary;
        }

        &.white {
            color: $white;

            .c-link {
                color: $white;
                border-bottom-color: $white;
            }
        }
    }
}
