/**
 * Link
 * @path: elements/link
 */

.is-desktop {
    a:hover {
        color: $anchor-color-hover;
    }
}

a {
    color: $anchor-color;
    transition: background-color .15s ease-in, color .15s ease-in, border-color .15s ease-in;
}