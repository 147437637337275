/**
 * Flexbox utility mixins
 * @path: tools/flexbox
 * 
 * 1. Flex-align
 */

// 1. Flex-align
// ------------------------
/**
 * Generates horizontal and vertical flexbox alignment classes
 *
 * @author Rianne Oosthoek <rianne@click.nl>
 * @param   {string}  $x-alignment:  null  Horizontal alignment to use. Can be `left`, `right`, `center`, 
 *     `justify`, or `spaced`. Or, set it to `null` (the default) to not set horizontal alignment.
 * @param   {string}  $y-alignment:  null  Vertical alignment to use. Can be `top`, `bottom`, `middle`, 
 *     or `stretch`. Or, set it to `null` (the default) to not set vertical alignment.
 * 
 * @credits: https://github.com/foundation/foundation-sites/blob/4e6c218993188d9f5368061d5dc1426978ab01bf/scss/util/_flex.scss
 */
 @mixin flex-align($x-alignment: null, $y-alignment: null) {
    @if $x-alignment {
        @if map-has-key($flex-x-alignment, $x-alignment) {
            $x-alignment: map-get($flex-x-alignment, $x-alignment);
        }
        @else {
            @warn '#{$x-alignment} is not a valid value for horizontal alignment. Use left, right, center, justify, or spaced.';
        }
    }

    @if $y-alignment {
        @if map-has-key($flex-y-alignment, $y-alignment) {
            $y-alignment: map-get($flex-y-alignment, $y-alignment);
        }
        @else {
            @warn '#{$y-alignment} is not a valid value for vertical alignment. Use top, bottom, middle, or stretch.';
        }
    }

    justify-content: $x-alignment;
    align-items: $y-alignment;
}
