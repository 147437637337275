/**
 * Hero
 * @path: components/hero
 */

.c-hero {
    &__slide {
        &--large {
            height: 70vh;
            min-height: 58rem;
        }
        &--medium {
            height: 50vh;
        }
    }
    &__content {
        margin-top: 8rem;
        padding: map-get($hero-content-padding, sm);
        background-color: $hero-content-background;
        border-radius: $global-radius;
    }
}

@include breakpoint(lg) {
    .c-hero {
        &__content {
            padding: map-get($hero-content-padding, md);
            margin-top: 0;
        }
    }
}
