/**
 * Navigation
 * @path: components/nav
 */

.is-desktop {
    .c-nav {
        &__anchor {
            :hover {
                color: $nav-item-hover;
            }
        }
    }
 }

.c-nav {
    box-shadow: $nav-shadow;

    &__bar {
        height: $nav-height;
        border-radius: $nav-radius;
        z-index: 99;
        background-color: $nav-background;
    }

    &__logo {
        width: 9.8rem;
        height: 4rem;
    }

    &__items {
        margin: 0;

        &--secondary {
            margin-left: auto;
        }
    }

    &__icon {
        width: 2rem;
        height: 2rem;
    }

    &__item {
        float: left;
        margin-left: 1.6rem;
        transition: $nav-item-transition;

        &--active {
            .c-nav__anchor {
                font-weight: 900;
                color: $primary;
            }
        }

        &--drawer {
            float: none;
            margin-bottom: 1.6rem;
            font-size: $nav-drawer-item-font-size;
        }
    }

    &__anchor {
        color: $black;

        @include external-url('fal', 'arrow-up-right-from-square', $body-font-size * 0.88);
    }

    &__drawer {
        position: absolute;
        width: 100%;
        height: 100vh;
        right: 0;
        top: 0;
        padding-top: 12rem;
        background-color: $white;
        z-index: -1;
        visibility: hidden;

        &--open {
            visibility: visible;
            animation: navDrawerSlideIn 600ms forwards;
        }

        &--close {
            visibility: visible;
            animation: navDrawerSlideOut 600ms forwards;
        }
    }

    &__shopping-cart {
        &-icon {
            background: url('/images/frontend/icon/cart.svg');
            display: block;
            width: 2rem;
            height: 2rem;
        }

        &:hover {
            .c-nav__shopping-cart-icon {
                background: url('/images/frontend/icon/cart_blue.svg');
            }
        }
    }

    &__counter {
        position: absolute;
        color: $white;
        top: -1.2rem;
        right: -1.2rem;
        background-color: $primary;
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        font-weight: 900;
        border: .1rem solid $white;
    }
}

@include breakpoint(lg) {
    .c-nav {
        box-shadow: none;
        margin-top: 1.6rem;

        &__bar {
            padding: $nav-padding;
            box-shadow: $nav-shadow;
        }
    }
}

@keyframes navDrawerSlideIn {
    0% {
        right: 100%;
    }
    100% {
        right: 0;
    }
}

@keyframes navDrawerSlideOut {
    0% {
        right: 0;
    }
    100% {
        right: 100%;
    }
}
