/**
 * Image
 * @path: components/image
 */

.c-image {
    &__slide {
        height: map-get($image-height, sm);
        width: 100%!important;
    }
    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: $image-border-radius;
    }

    &--margin {
        margin-bottom: 1.6rem;
    }
}

@include breakpoint(md) {
    .c-image {
        &__slide {
            height: map-get($image-height, md);
        }
        &--margin {
            margin-bottom: 0;
        }
    }
}