/**
 * Switch
 * @path: components/switch
 */

.c-switch {
    position: relative;
    display: inline-block;
    width: 3rem;
    height: 1.7rem;

    input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked {
            +.c-switch__slider {
                background-color: $primary;
            }
            +.c-switch__slider {
                &:before {
                    transform: translateX(1.3rem);
                }
            }
        }
    }

    &__slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $medium-gray;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 1.7rem;

        &:before {
            position: absolute;
            content: "";
            height: 1.3rem;
            width: 1.3rem;
            left: .2rem;
            bottom: .2rem;
            background-color: $white;
            -webkit-transition: .4s;
            transition: .4s;
            border-radius: 50%;
        }
    }
}