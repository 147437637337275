/**
 * Form styling
 * @path: components/form
 */

.is-desktop {
    .c-form {
    }
}

.c-form {
    &__group {
        display: flex;
        flex-direction: column;
        margin-bottom: 2.4rem;
        &--infix {
            display: flex;
            flex-direction: column;
            margin-bottom: 2.4rem;
        }
    }

    &__input {
        margin-top: .4rem;
        padding: 1.2rem .8rem;
        border-radius: .5rem;
        border: .1rem solid $dark-gray;
        &:disabled {
            background-color: $light-gray;
            cursor: not-allowed;
        }
    }

    &__submit {
        margin-bottom: 1.2rem;
    }

    &__message {
        margin-top: .6rem;
        margin-bottom: 0;
        font-size: 1.4rem;
        &--error {
            color: $alert;
        }
    }
}

@include breakpoint(sm) {
    .c-form {
        &__button-group {
            display: flex;
            align-items: center;
        }

        &__submit {
            margin-bottom: 0;
            margin-right: 1.2rem;
        }
    }
}

@include breakpoint(md) {
    .c-form {
        &--grow {
            display: flex;
            flex-direction: row;

            .c-form__group {
                flex: 1 1 0%;
                margin-right: 1.2rem;

                &--infix {
                    flex: 1 1 0%;
                    flex-grow: inherit;
                    margin-right: 1.2rem;
                }

                &:nth-last-child(1) {
                    margin-right: 0;
                }
            }
        }
    }
}

