/**
 * Button
 * @path: elements/button
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
    border: none;
    background: transparent;
    transition: background-color .2s ease-in, background .2s ease-in, color .2s ease-in, border-color .2s ease-in;
}
