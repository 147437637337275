/**
 * Animations
 * @path: objects/animation
 */

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
