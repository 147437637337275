/**
 * Alert
 * @path: components/alert
 */

/**
 * Alert boxes
 * From Foundation 5
 * @path: components/alert-box
 */

.c-alert {
    @include alert;

    &--success {
        @include alert-style($success);
    }

    &--warning {
        @include alert-style($warning);
    }

    &--alert {
        @include alert-style($alert);
    }

    &--info {
        @include alert-style($info);
    }

    &--radius{
        border-radius: $alert-radius;
    }

    &--sticky {
        max-width: $alert-sticky-max-width;
        min-height: $alert-sticky-min-height;
        width: 100%;
        margin: $alert-sticky-margin;
        position: fixed;
        left: 50%;
        z-index: 1000;
        transform: translateX(-50%);

        .c-alert {
            min-height: $alert-sticky-min-height;
        }
    }
}