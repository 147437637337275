/**
 * Link
 * @path: tools/link
 */

/**
 * 3. Mixin for adding the external url properties
 *
 * @author Yonita van Oudheusden <yonita@click.nl>
 */
@mixin external-url(
    $variant: 'fas',
    $icon: '',
    $size: $body-font-size
) {
    &[target="_blank"] {
        display: inline-flex;
        align-items: center;

        &::after {
            @extend %font-awesome;
            @extend %#{$variant};
            margin-left: .4rem;
            font-size: $size;
            content: map-get($fa-icons, #{$icon});
        }
    }
}
