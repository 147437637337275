/**
 * Card
 * @path: components/Card
 */

 .is-desktop {
    .c-card {
        &:hover {
            box-shadow: $card-box-shadow-hover;
        }

        &__title {
            &:hover {
                color: $card-title-color-hover;
            }
        }

        &__image {
            &:hover {
                transform: scale(1.05)
            }
        }
    }
 }

 .c-card {
    height: 100%;
    background-color: $card-background;
    border-radius: $card-radius;
    box-shadow: $card-box-shadow;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: $card-transition;
    backface-visibility: hidden;

    &__image {
        width: $card-image-width;
        height: $card-image-height;
        transition: $card-image-transition;
        object-fit: $card-image-fit;

        &-wrapper {
            height: $card-image-height;
        }
    }

    &__content {
        height: 100%;
        padding: $card-padding;
        display: flex;
        flex-direction: column;
    }

    &__title {
        color: $card-title-color;
        transition: $card-title-transition;
    }

    &__body {
        display: flex;
        flex-grow: 1;
    }

    &--loaded {
        opacity: 0;
        animation: fadeIn 0.8s forwards;
    }
}
