
/**
 * Handy helper functions
 * @path: tools/unit
 *
 *  1. Unit removal
 */


// 1. Unit removal
// ------------------------
/**
 * Removes the unit (e.g. px, em, rem) from a value, returning the number only.
 *
 * @author Rianne Oosthoek <rianne@click.nl> 
 * @param {Number} $number - Number to strip unit from.
 * @returns {Number} The same number, sans unit.
 */
 @function -cl-strip-unit($number) {
    @return $number / ($number * 0 + 1);
}
