/**
 * Scroll helper classes
 * @path: utilities/scroll
 */

 .u-disable-scroll {
    width: 100%;
    overflow: hidden;
    position: fixed;
}
