/**
 * Headings
 * @path: components/headings
 */

/**
 * Creates helper classes for heading styles
 * 
 * @var {Map} $headings - settings/custom
 * @output e.g. '.c-heading-small'
 */
@each $breakpoint, $headers in $headings {
    @include breakpoint($breakpoint) {
        @each $header, $styles in $headers {
            .#{$header} {
                @each $property, $value in $styles {
                    #{$property} : #{$value}; 
                }
            }
        }
    }
}
