/**
 * Block loader
 * @path: components/block-loader
 */

.c-block-loader {   
    width: 100%;
    height: 2rem;
    background-color: $light-gray;
    position: relative;
    margin-bottom: 1.2rem;
    border-radius: .5rem;
    overflow: hidden;

    &--large {
        height: 4rem;
    }
    &--title {
        width: 30rem;
    }
    &--text {
        width: 12rem;
    }
    &--image {
        height: 24rem;
    }

    &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        transform: translateX(-100%);
        background: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(rgba($medium-gray, 0.2)),to(transparent)); 
        background: linear-gradient(90deg, transparent,rgba($medium-gray, 0.2), transparent);
        animation: loading 0.8s infinite;
    }
}

@keyframes loading {
    100% {
        transform: translateX(100%);
    }
}
