/**
 * Header
 * @path: components/header
 */

.c-header {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 998;
    background-color: $white;

    &--open {
        animation: HeaderSlideIn 600ms forwards
    }

    &--close {
        animation: HeaderSlideOut 600ms forwards
    }
}

@include breakpoint(lg) {
    .c-header {
        background-color: transparent;
    }
}

@keyframes HeaderSlideIn {
    0% {
        top: -100px;
    }
    100% {
        top: 0;
    }
}

@keyframes HeaderSlideOut {
    0% {
        top: 0;
    }
    100% {
        top: -150px;
    }
}