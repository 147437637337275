/**
 * Bodystyle
 * @path: elements/body
 */

body {
    font-size: $body-font-size;
    font-family: $body-font-family;
    line-height: $global-line-height;
    background-color: $body-background;
    color: $body-font-color;
    display: flex;
    flex-direction: column;
}