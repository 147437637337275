/*
 * Alert boxes (from Foundation 5)
 *
 * 1. Alert base
 * 2. Alert style
 * 3. Alert close
 * 4. Alert
 */

// 1. Default alert base.
// ------------------------
@mixin alert-base {
    margin-bottom: $alert-bottom-margin;
    padding: $alert-padding-top $alert-padding-right $alert-padding-bottom $alert-padding-left;
    position: relative;
    z-index: 1;
    font-weight: $alert-font-weight;
    border-style: $alert-border-style;
    border-width: $alert-border-width;
    border-radius: $alert-radius;
    font-size: $alert-font-size;
    transition: opacity $alert-transition-speed $alert-transition-ease;
    display: flex;
}

// 2. Add alert styles
// ------------------------
@mixin alert-style( $color: $primary ) {
    // Lightness percentage of the background color.
    $background-lightness: lightness($color);

    background-color: scale-color($color, $lightness: 88%, $saturation: -20%);
    border-color: $color;
    color: $color;
}

// 3. We use this to create the close button.
// ------------------------
@mixin alert-close( $color: $primary ) {
    padding: $alert-close-padding;
    position: absolute;
    top: 50%;
    right: .8rem;
    z-index: 10;
    font-size: $alert-close-font-size;
    text-decoration:none;
    line-height: .9;
    color: $color;
    cursor: pointer;
    transition: color .2s ease-out;
    transform: translateY(-50%);

    &:hover,
    &:focus {
        color: darken($color, 15%);
    }
}

// 4. Single mixin to create the alert
// ------------------------
@mixin alert( $background: $primary ) {
    @include alert-base;
    @include alert-style($background);
}
