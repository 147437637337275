/**
 * Grid mixins and functions
 * @path: tools/grid
 *
 *  1. Calculate column size percentage
 *  2. Gutters
 *  3. Grid sizes
 *  4. Spacing
 */

// 1. Calculate column size percentage
// ------------------------
@function -cl-get-column-percent($column-number) {
    @return $column-number / $grid-column-count * 100%;
}

// 2. Gutters
// ------------------------
/** 
 * Set the gutters on a column
 * 
 * @param {Number|Keyword} $gutter [auto]
 *   Spacing between columns, accepts multiple values:
 *   - A single value will make the gutter that exact size.
 *   - A breakpoint name will make the gutter the corresponding size in the $gutters map.
 *   - "auto" will make the gutter responsive, using the $gutters map values.
 * @param {Number|Map} $gutters [$grid-column-gutter] - Gutter map or single value to use. Responsive gutter settings by default.
 */
@mixin grid-column-gutter (
    $gutter: auto,
    $gutters: $grid-column-gutter
) {
    @include create-breakpoints-from-map($gutter, $gutters) {
        $padding: $-cl-breakpoint-value / 2;

        padding-right: $padding;
        padding-left: $padding;
    }
}

// 3. Grid sizes
// ------------------------
/**
 * Generate a set of grid column classes using a breakpoint-size
 * .o-col-[breakpoint-size]-[number] for a column that covers a specific number of columns (e.g. 1-12 by default)
 * .o-push-[breakpoint-size]-[number] for pushing a col a specific number of columns (e.g. 1-11 by default)
 * .o-push-[breakpoint-size]-reset for resetting a col's offset for that and larger breakpoint-sizes
 */
@mixin grid-sizes( $breakpoint-size ) {
    // Run the loop for as many columns as specified
    @for $i from 1 through $grid-column-count {
        .o-col--#{$breakpoint-size}-#{$i} {
            flex-basis: -cl-get-column-percent($i);
            max-width:  -cl-get-column-percent($i);
        }
    }
    @for $i from 0 through $grid-column-count {
        // Don't include the .off class for the last column
        @if ( $i != $grid-column-count ) {
            .o-offset--#{$breakpoint-size}-#{$i} {
                margin-left: -cl-get-column-percent($i);
            }
        }
    }   
}

// 4. Spacing
// ------------------------
@mixin grid-row-nest {
    @each $breakpoint, $value in $grid-column-gutter {
        @if map-has-key($breakpoints, $breakpoint) {
            @include breakpoint($breakpoint) {
                $margin: $value / 2 * -1;

                margin-left: $margin;
                margin-right: $margin;
            }
        }
    }
}
