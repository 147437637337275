/**
 * Visibility
 * @path: utilities/visibility
 */

@each $size in $breakpoint-classes {
    .u-show-for-#{$size} {
        @include show-for($size);
    }

    .u-hide-for-#{$size} {
        @include hide-for($size);
    }
}

.u-hidden {
    visibility: hidden;
}
