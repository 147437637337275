/**
* Icon styling
* @path: components/icon
* @cheatsheet: https://fontawesome.com/icons?d=gallery&q=&s=brands,regular,solid
*
*/

@font-face {
    font-family: $fa-font-name-light;
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('/fonts/font-awesome/fa-light-300.otf') format('opentype');
}

@font-face {
    font-family: $fa-font-name-regular;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/font-awesome/fa-regular-400.otf') format('opentype');
}

%font-awesome {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}

%fal { font-family: $fa-font-name-light; }
%far { font-family: $fa-font-name-regular; }

.far,
.fal {
    @extend %font-awesome;
}

.far { @extend %far; }
.fal { @extend %fal; }

@each $key, $value in $fa-icons {
    .fa-#{$key}::before {
        content: $value;
    }
}

.user-icon {
    background: url('/images/frontend/icon/user.svg');
    display: block;
    width: 1.6rem;
    height: 1.8rem;
    float: right;
    margin-top: -.2rem;
}
