/**
 * Horizontal and vertical spacing
 * @path: utilities/spacing
 */

/**
 * Create helpers classes for top and bottom padding of elements
 *
 * @var {Map} $v-spacing - settings/custom
 * @var {List} $spacing-type - determines for which spacing types the classes should be created
 * @output e.g. '.u-padding-top-small'
 */
@each $breakpoint, $sizes in $v-spacing {
    @include breakpoint($breakpoint) {
        @each $spacing-type in margin, padding {
            @each $size, $value in $sizes {
                @if( $size == 'default') {
                    .u-#{$spacing-type}-vertical { #{$spacing-type}-top: #{$value}; #{$spacing-type}-bottom: #{$value}; }
                    .u-#{$spacing-type}-top { #{$spacing-type}-top: #{$value}; }
                    .u-#{$spacing-type}-bottom { #{$spacing-type}-bottom: #{$value}; }
                    .u-#{$spacing-type}-left { #{$spacing-type}-left: #{$value}; }
                    .u-#{$spacing-type}-right { #{$spacing-type}-right: #{$value}; }
                } @else {
                    .u-#{$spacing-type}-vertical-#{$size} { #{$spacing-type}-top: #{$value}; #{$spacing-type}-bottom: #{$value}; }
                    .u-#{$spacing-type}-top-#{$size} { #{$spacing-type}-top: #{$value}; }
                    .u-#{$spacing-type}-bottom-#{$size} { #{$spacing-type}-bottom: #{$value}; }
                    .u-#{$spacing-type}-left-#{$size} { #{$spacing-type}-left: #{$value}; }
                    .u-#{$spacing-type}-right-#{$size} { #{$spacing-type}-right: #{$value}; }
                }
            }
        }
    }
}

/** 
 * Creates spacing classes that apply from the breakpoint they're named after
 *
 * @var {List} $spacing-type - determines for which spacing types the classes should be created
 * @output e.g. '.u-no-small-padding-top'
 */
//https://github.com/foundation/foundation-sites/blob/91a0f2a02977127696e49b40e435d15de1948442/scss/util/_mixins.scss#L271
// @include -zf-each-breakpoint{
//     @each $spacing-type in margin, padding {
//         .u-no-#{$-zf-size}-#{$spacing-type}-vertical { #{$spacing-type}-top: 0 !important; #{$spacing-type}-bottom: 0 !important; }
//         .u-no-#{$-zf-size}-#{$spacing-type}-horizontal { #{$spacing-type}-left: 0 !important; #{$spacing-type}-right: 0 !important; }
//         .u-no-#{$-zf-size}-#{$spacing-type}-top { #{$spacing-type}-top: 0 !important; }
//         .u-no-#{$-zf-size}-#{$spacing-type}-bottom { #{$spacing-type}-bottom: 0 !important; } 
//     }
// }

.u-no-margin-left{ margin-left: 0 !important }
.u-no-margin-top{ margin-top: 0 !important; }
.u-no-margin-bottom{ margin-bottom: 0 !important; }
.u-no-padding-top{ padding-top: 0 !important; }
.u-no-padding-bottom{ padding-bottom: 0 !important; }
.u-padding-top-header {
    padding-top: 8rem;
}
