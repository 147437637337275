/**
 * Vue select overrides
 * @path: vendor/vue-select
 */

:root {
    --vs-dropdown-min-width: unset;
}

.v-select {
    margin-left: 1.2rem;
}

.vs__dropdown-toggle {
    height: 3.2rem;
    width: 6.2rem;
    border-color: $black;
}

.vs__dropdown-menu {
    width: 6.2rem;
    min-width: none;
}

.vs__open-indicator {
    fill: $black;
}

.vs__search,
.vs__search:focus {
    font-size: 100%;
}