/**
 * Background
 * @path: components/background
 */

 .o-background {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;

    &--contain {
        background-size: contain;
    }
}
