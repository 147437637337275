/**
 * Visibility mixins
 * @path: tools/visibility
 */

@mixin show-for($size) {
    $size: map-get($breakpoints, $size);

    // Max value is 0.2px under the next breakpoint (0.02 / 16 = 0.00125).
    // Use a precision under 1px to support browser zoom, but not to low to avoid rounding.
    $size: -cl-convert-breakpoint-to-em($size) - 0.00125;

    @include breakpoint($size down) {
        display: none !important;
    }
}

@mixin hide-for($size) {
    @include breakpoint($size) {
        display: none !important;
    }
}