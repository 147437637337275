/**
 * Custom radio buttons
 * @path: components/radio
 */

.c-radio {
    display: flex;
    align-items: center;
    padding-bottom: 2.4rem;

    &__label {
        padding: 1rem;
        border: .1rem solid $black;
        border-radius: .5rem;
        margin-right: 2.4rem;
        cursor: pointer;

        &:hover {
            background-color: $black;
            color: $white;
        }

        &--disabled {
            pointer-events: none;
            opacity: 0.4;
        }
    }

    &__inner {
        display: none;
        &:checked {
            +.c-radio__label {
                background-color: $primary;
                border-color: $primary;
                color: $white;
            }
        }
    }
}