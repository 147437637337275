/**
 * Dimensions
 * @path: utilities/dimension
 */

.u-full-width {
    width: 100%;
}

.u-full-height {
    height: 100%;
}
