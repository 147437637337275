/**
 * Cookie
 * @path: components/cookie
 */

.c-cookie {
    width: 100%;
    background-color: $white;
    border-radius: .5rem;
    padding: 2.4rem 2.4rem 0;

    &__buttons {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 1.2rem;
    }

    &__button {
        margin-bottom: 1.2rem;
    }
}

@include breakpoint(md) {
    .c-cookie {
        width: 70%;
    }
}

@include breakpoint(lg) {
    .c-cookie {
        width: 50%;
    }
}
