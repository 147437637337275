/**
 * Panel
 * @path: components/panel
 */

.c-panel {
    background-color: $content-block-background;
    padding: $content-block-padding;
    border-radius: $content-block-border-radius;
    color: $content-block-color;
    word-break: break-word;

    &--form {
        border: .1rem solid $black;
    }

    &__header {
        &--column {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__select-wrapper {
        padding: $content-block-padding;
        border-radius: $content-block-border-radius;
        border: $content-block-inner-border-color;
        margin-bottom: 2.4rem;
        position: relative;
        // Needed for z-index issues due to the animation property that is set on the panel element. 
        // With an animation and/or opacity set, the element gets its own stacking order, 
        // which results in the dropdown of amount of tickets falling below the next panel element
        @for $i from 1 through 100 {
            &:nth-child(#{$i}) {
                z-index: 100 - $i;
            }
        }

        &--disabled {
            border-color: $dark-gray;
            color: $dark-gray;
            background-color: $light-gray;
        }

        &--loading {
            border-color: $medium-gray;
        }

        &--loaded {
            opacity: 0;
            animation: fadeIn 0.8s forwards;
        }
    }

    &__items {
        margin-bottom: -2.4rem;
    }

    &__body {
        padding: $content-block-padding 0;
        .c-panel__item {
            margin-bottom: 1.2rem;
            &:nth-last-child(1) {
                margin-bottom: 0;
            }
        }
    }

    &__delete {
        margin-left: -.5rem;
        padding: .5rem;
        color: $dark-gray;
        cursor: pointer;
        &--active {
            transition: $button-transition; 
        }
        &:hover {
            .c-panel__delete--active {
                color: $primary;
            }
        }
    }

    &__footer {
        border-top: .1rem solid $dark-gray;
        padding-top: 2.4rem;
    }

    &__price {
        display: flex;
        justify-content: flex-end;
        min-width: 7rem;
    }
}

@include breakpoint(sm) {
    .c-panel {
        &__header {
            &--column {
                flex-direction: unset;
            }
        }
    }
}

@include breakpoint(md) {
    .c-panel {
        &__delete {
            margin-top: 0;
        }
    }
}
