/**
 * Mixins and functions for breakpoint media queries
 * @path: tools/breakpoint
 *
 *  1. Breakpoint
 *  2. Breakpoint from map
 *  3. Get media query string
 *  4. Breakpoint to em
 *  5. Create media query string
 */

// Global breakpoint variables that may be used in mixin declarations
$-cl-breakpoint-size: null;
$-cl-breakpoint-value: null;

// 1. Breakpoint
// ------------------------
/**
 * Mixin to manage responsive breakpoints
 * 
 * Examples:
 * @include breakpoint(md)      // Output: @media screen and (min-width: 48em)
 * @include breakpoint(md up)   // Output: @media screen and (min-width: 48em)
 * @include breakpoint(lg only)  // Output: @media screen and (min-width: 62em) and (max-width: 74.99875em)
 * @include breakpoint(lg down)  // Output: @media screen and (max-width: 74.99875em)
 * @include breakpoint(576px)       // Output: @media screen and (min-width: 36em)
 * @include breakpoint(75em).       // Output: @media screen and (min-width: 75em)
 * @include breakpoint(landscape)   // Output: @media screen and (orientation: landscape)
 * @include breakpoint(portrait)    // Output: @media screen and (orientation: portrait)
 * 
 * I.E. for combination of orientation and min/max media query, use include inside an include:
 * @include breakpoint(lg) {
 *     @include breakpoint(landscape) {
 *         // ...
 *     }
 * }
 * // Output: @media screen and (min-width: 62em) and (orientation: landscape)
 * 
 * @param {String} $breakpoint - Breakpoint name, px or em value
 */
@mixin breakpoint($values...) {
    @for $i from 1 through length($values) {
        // Get single value
        $value: nth($values, $i);
        // Media query String
        $media-query-string: -cl-get-media-query-string($value);
        // Global current breakpoint size
        $-cl-breakpoint-size: nth($value, 1) !global; // get the first value to account for `only` and `down` keywords

        // If `$mq-string` is still an empty string (no value has been passed to the mixin), 
        // no media query is needed
        @if $media-query-string == '' {
            @content;
        }
        // Otherwise, wrap the content in a media query
        @else {
            @media screen and #{$media-query-string} {
                @content;
            }
        }
    }
}

// 2. Breakpoint from map
// ------------------------
/** 
 * Generate the `@content` passed to the mixin with a value `$-cl-breakpoint-value` related to a breakpoint, 
 * depending on the `$name` parameter:
 * - For a single value, `$-cl-breakpoint-value` is this value.
 * - For a breakpoint name, `$-cl-breakpoint-value` is the corresponding breakpoint value in `$map`.
 * - For "auto", `$-cl-breakpoint-value` is the corresponding breakpoint value in `$map` and is passed 
 * to `@content`, which is made responsive for each breakpoint of `$map`.
 * 
 * @param {Number|Array|Keyword} $name [auto] - Single value, breakpoint name, or list of breakpoint 
 * names to use. "auto" by default.
 * @param {Number|Map} $map - Map of breakpoints and values or single value to use.
 */ 
@mixin create-breakpoints-from-map(
    $name: auto,
    $map: null
) {
    @if $name == auto and type-of($map) == 'map' {
        @each $key, $value in $map {
            @include breakpoint($key) {
                @include create-breakpoints-from-map($value, $map) {
                    @content;
                }
            }
        }
    }
    @else {
        // breakpoint name
        @if type-of($name) == 'string' {
            $breakpoint-value: map-get($map, $name);
            @if $breakpoint-value != null {
                $name: $breakpoint-value;
            }
        }

        // Global current breakpoint value
        $-cl-breakpoint-value: $name !global;
        @content;
    }
}

// 3. Get media query string
// ------------------------
/**
 * Generates a media query string matching the input value
 *
 * @author Rianne Oosthoek <rianne@click.nl>
 * @param   {[type]}  $value:  $zero-breakpoint  [description]
 * @return  {string}  $value [xsmall] - Breakpoint name, px, or em value to process.
 */
@function -cl-get-media-query-string($value: $zero-breakpoint) {
    $breakpoint: nth($value, 1);
    // Direction of media query (up, down, or only)
    $direction: if(length($value) > 1, nth($value, 2), up);
    // Given breakpoint name
    $breakpoint-name: null;
    // Value for max-width media queries
    $breakpoint-min: null;
    // Value for min-width media queries
    $breakpoint-max: null;
    // Value of the next breakpoint in the `$breakpoints` map
    $breakpoint-next: null;

    // 1. ORIENTATION
    @if $breakpoint == 'landscape' or $breakpoint == 'portrait' {
        @return '(orientation: #{$breakpoint})';
    }

    // If a breakpoint name is given, get its value from the `$breakpoints` map.
    @if type-of($breakpoint) == 'string' {
        @if map-has-key($breakpoints, $breakpoint) {
            $breakpoint-name: $breakpoint;
            $breakpoint: map-get($breakpoints, $breakpoint-name);
            // Need for `only` and `down` media queries
            $breakpoint-next: -cl-get-next-map-key($breakpoints, $breakpoint-name);
        }
        @else {
            $breakpoint: 0;
            @warn '-cl-get-media-query-string(): "#{$value}" is not defined in your `$breakpoints` setting.';
        }
    }

    // Given breakpoint name doesn't exist in breakpoints map so `only` range cannot
    // be executed, give warning
    @if not $breakpoint-name and $direction == 'only' {
        @warn '-cl-get-media-query-string(): Only named media queries can have an `only` range.';
        @return null;
    }

    // Create a minimum limit for `only` and `up` media query ranges
    @if $direction == 'only' or $direction == 'up' {
        // Create minimum limit breakpoint and convert it to em
        $breakpoint-min: -cl-convert-breakpoint-to-em($breakpoint);
    }
    // Create a maximum limit for `only` and `down` media query ranges
    @if $direction == 'only' or $direction == 'down' {
        // If the breakpoint-name is not null, use it as max limit.
        @if not $breakpoint-name {
            $breakpoint-max: -cl-convert-breakpoint-to-em($breakpoint);
        }
        // If the breakpoint is named, the max limit is the following breakpoint - 1px.
        @else if $breakpoint-next {
            // Max value is 0.2px under the next breakpoint (0.02 / 16 = 0.00125).
            // Use a precision under 1px to support browser zoom, but not too low to avoid rounding.
            $breakpoint-max: -cl-convert-breakpoint-to-em($breakpoint-next) - 0.00125;
        }
    }

    // 2. MIN AND MAX LIMITS
    @return -cl-create-media-query-string($breakpoint-min, $breakpoint-max);
}

// 4. Breakpoint to em
// ------------------------
/**
 * Convert given value to em. Used in media queries
 *
 * @author Rianne Oosthoek <rianne@click.nl>
 * @param   {string}  $value  unitless, pixel or rem value
 * @return  {string} number in em value  
 */
@function -cl-convert-breakpoint-to-em($value) {
    // Pixel and unitless values are converted to rems
    @if unit($value) == 'px' or unitless($value) {
        $value: -cl-strip-unit($value) / 16 * 1rem;
    } @else if unit($value) == 'rem' {
        $value: 0;
        @warn '-cl-convert-breakpoint-to-em(): Please use px, em of named breakpoint values only'
    }

    // Then the value is converted to ems
    @return -cl-strip-unit($value) * 1em;
}

// 5. Create media query string
// ------------------------
/**
 * Return media query string from the given min and/or max limits.
 * If a limit is equal to `null` or `0`, it is ignored.
 * 
 * @author Rianne Oosthoek <rianne@click.nl>
 * @param {Number} $breakpoint-min [0] - Min media query limit.
 * @param {Number} $breakpoint-max [0] - Max media query limit.
 * @param {String} $breakpoint-name-min ['min-width'] - Name of the min media query limit.
 * @param {String} $breakpoint-name-max ['max-width'] - Name of the max media query limit.
 * @returns {String} Media Query string.
 */
@function -cl-create-media-query-string(
    $breakpoint-min: 0,
    $breakpoint-max: 0,
    $breakpoint-name-min: 'min-width',
    $breakpoint-name-max: 'max-width'
) {
    $media-query-min: if($breakpoint-min and $breakpoint-min > 0, '(#{$breakpoint-name-min}: #{$breakpoint-min})', null);
    $media-query-max: if($breakpoint-max and $breakpoint-max > 0, '(#{$breakpoint-name-max}: #{$breakpoint-max})', null);
    $delimiter: ' and ';
    $media-query-string: '';

    // Create min media query string
    @if $media-query-min and str-length($media-query-min) > 0 {
        $media-query-string: $media-query-string + $media-query-min;

        // Add delimiter to media query string
        @if $delimiter and str-length($delimiter) > 0 and $media-query-max and str-length($media-query-max) > 0 {
            $media-query-string: $media-query-string + $delimiter;
        }
    }
    // Create max media query string
    @if $media-query-max and str-length($media-query-max) > 0 {
        $media-query-string: $media-query-string + $media-query-max;
    }

    @return $media-query-string;
}

@function -cl-bp-serialize($map) {
    $str: '';
    @each $key, $value in $map {
      $str: $str + $key + '=' + -zf-bp-to-em($value) + '&';
    }
    $str: str-slice($str, 1, -2);
  
    @return $str;
}
