/**
 * Summary
 * @path: components/summary
 */

.c-summary {
    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 2.4rem;
        border-bottom: .1rem solid $black;
    }
    &__footer {
        padding: 2.4rem 0;
        text-align: right;
    }
    &__item {
        padding: 2.4rem 0;
        display: flex;
        flex-direction: column;
        border-bottom: .1rem solid $black;
    }

    &__item-inner {
        margin-bottom: 2.4rem;
    }
}

@include breakpoint(md) {
    .c-summary {
        &__item {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            &--column {
                flex-direction: column;
                align-items: unset;
                justify-content: unset;
            }
        }
        &__item-inner {
            margin-bottom: 0;
        }
    }
}