/**
 * Loader
 * @path: components/loader
 */

.c-loader {
    color: $loader-block-background;
    display: block;
    position: relative;
    width: 100%;

    &::after,
    &::before {
        content: '';
        box-sizing: border-box;
        position: absolute;
    }

    &.is-xsmall {
        width: $loader-xsmall-block-size * 2;
        height: $loader-xsmall-block-size * 2;

        .c-loader__inner {
            &::after,
            &::before {
                width: $loader-xsmall-block-size;
                height: $loader-xsmall-block-size;
            }

            &::before {
                top: calc(50% - #{$loader-xsmall-block-size});
                left: calc(50% - #{$loader-xsmall-block-size});
            }
        }
    }

    &__inner {
        &::after,
        &::before {
            content: '';
            box-sizing: border-box;
            position: absolute;
            width: $loader-block-size;
            height: $loader-block-size;
            background-color: $loader-block-background;
        }

        &::after {
            top: 50%;
            left: 50%;
            animation: blockAnimationAfter 2.4s cubic-bezier(0, 0, 0.24, 1.21) infinite;
        }

        &::before {
            top: calc(50% - #{$loader-block-size});
            left: calc(50% - #{$loader-block-size});
            animation: blockAnimationBefore 2.4s cubic-bezier(0, 0, 0.24, 1.21) infinite;
        }
    }
}

@keyframes blockAnimationBefore {
    0%, 100% {
        transform: none;
    }

    25% {
        transform: translateX(100%);
    }

    50% {
        transform: translateX(100%) translateY(100%);
    }

    75% {
        transform: translateY(100%);
    }
}

@keyframes blockAnimationAfter {
    0%, 100% {
        transform: none;
    }

    25% {
        transform: translateX(-100%);
    }

    50% {
        transform: translateX(-100%) translateY(-100%);
    }

    75% {
        transform: translateY(-100%);
    }
}
