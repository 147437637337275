/**
 * Agile slider
 * @path: vendor/agile/slider
 */
 
.agile {
    &__dots {
        position: absolute;
        bottom: 1.6rem;
        left: 50%;
        transform: translateX(-50%);
    }
    &__dot {
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 50%;
        border: .2rem solid $white;
        margin-right: 1.6rem;
        &--current {
            background-color: $white;
        }
        &:nth-last-child(1) {
            margin-right: 0;
        }
        button {
            width: 100%;
            height: 100%;
        }
    }
}
