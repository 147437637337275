/**
 * Headings
 * @path: components/headings
 */

 .c-hamburger {
    width: 2.5rem;
    height: 6.4rem;
    margin-right: 2.4rem;
    margin-bottom: .9rem;
    padding-left: 0;
    position: relative;
    z-index: 200;

    &__inner {
        top: 2.1rem;
        transition: background-color 0s linear .13s;
        &, &::after, &::before {
            width: 2.5rem;
            height: .2rem;
            position: absolute;
            background-color: $black;
            display: block;
            content: '';
        }
        &::before {
            top: 1rem;
            transition: top 0.1s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
        &::after {
            top: 2rem;
            transition: top 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
        
        &--animate {
            background-color: transparent;
            transition-delay: .22s;
            &::before{
                top: 0;
                transition: top .1s cubic-bezier(.33333,0,.66667,.33333) .15s, transform .13s cubic-bezier(.215,.61,.355,1) .22s;
                transform: translate3d(0,10px,0) rotate(45deg);
            }
            &::after{
                top: 0;
                transition: top .2s cubic-bezier(.33333,0,.66667,.33333), transform .13s cubic-bezier(.215,.61,.355,1) .22s;
                transform: translate3d(0,10px,0) rotate(-45deg);
            }        
        }
    }
}
