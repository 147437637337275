/**
 * Grid
 * @path: objects/grid
 * @credits: https://github.com/kodolen/ljvw-portfolio/blob/master/src/style/_flex-grid.scss
 */

.o-row {
    max-width: $grid-max-width;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .o-row {
        @include grid-row-nest;
    }
    &--reverse {
        flex-direction: row-reverse;
    }
}

.o-col {
    @include grid-column-gutter( $gutters: $grid-column-gutter );

    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 100%;
}

/**
 * For each namespace, wrap the col width/offset measurements in their breakpoint media query
 */
@each $value in $breakpoints {
    @if length($value) == 1 {
        $namespace: nth($value, 1);

        @include grid-sizes( $namespace );

    } @else {
        $namespace: nth($value, 1);
        $size: nth($value, 2);

        @media only screen and (min-width: #{$size}) {
            @include grid-sizes( $namespace );
        }
    }
}
