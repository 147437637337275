/**
 * Overlay
 * @path: components/overlay
 */

.c-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba($black, .6);
    display: none;
    align-items: center;
    justify-content: center;

    &--open {
        display: flex;
    }
}
