/**
 * Progress
 * @path: components/progress
 */

.c-checkout-bar {
    &__items {
        position: relative;
        display: flex;
        justify-content: space-between;
    }

    &__step {
        flex-basis: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;

        &:first-child {
            .c-checkout-bar__line::before {
                content: unset;
            }
        }

        &:last-child {
            .c-checkout-bar__line::after {
                content: unset;
            }
        }

        &.is-current {
            .c-checkout-bar__line {
                &::before {
                    background-color: $secondary;
                }
            }

            .c-checkout-bar__circle {
                background: linear-gradient(-90deg, $primary 0%, $secondary 100%);
                background-color: $secondary;
                color: $white;
                border: unset;
            }

            .c-checkout-bar__name {
                color: $secondary;
            }
        }

        &.is-completed {
            .c-checkout-bar__line {
                &::before,
                &::after {
                    background-color: $secondary;
                }
            }

            .c-checkout-bar__circle {
                color: $secondary;
                border-color: $secondary;
            }

            .c-checkout-bar__name {
                color: $secondary;
            }
        }
    }

    &__line {
        width: 100%;
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: center;

        &::before,
        &::after {
            width: 50%;
            height: .2rem;
            position: absolute;
            z-index: 1;
            top: 50%;
            background-color: $dark-gray;
            content: '';
            transform: translateY(-50%);
        }

        &::before {
            left: 0;
        }

        &::after {
            left: 50%;
        }
    }

    &__circle {
        width: 3.8rem;
        height: 3.8rem;
        position: relative;
        z-index: 5;
        color: $dark-gray;
        background-color: $white;
        border: .1rem solid $dark-gray;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__name {
        margin-top: .8rem;
        color: $dark-gray;
        font-size: 1.2rem;
        white-space: nowrap;
    }
}

@include breakpoint(md) {
    .c-checkout-bar {
        &__name {
            font-size: 1.6rem;
        }
    }
}