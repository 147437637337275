/**
 * Footer
 * @path: components/footer
 */

.is-desktop {
    .c-footer {
        &__item {
            a:hover,
            &-anchor:hover {
                color: $anchor-color;
            }
        }
    }
}

.c-footer {
    background-color: $footer-background;
    color: $footer-color;
    padding: $footer-padding;
    font-size: $footer-font-size;
    flex-shrink: 0;

    &__inner {
        display: flex;
        flex-direction: column-reverse;
    }

    &__items {
        margin-bottom: 1.2rem;
    }

    &__item {
        margin-bottom: 1.2rem;

        a,
        &-anchor {
            color: $white;

            @include external-url('fal', 'arrow-up-right-from-square', $body-font-size * 0.75);
        }
    }
}

 @include breakpoint(md) {
    .c-footer {
        &__inner {
            flex-direction: row;
            justify-content: space-between;
        }
        &__items {
            margin-bottom: 0;
        }
        &__item {
            float: left;
            margin-left: 2.4rem;
            margin-bottom: 0;
        }
    }
 }